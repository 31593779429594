import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { ValueSet } from 'spinel/dist/utils/sr/terminology';
import { ErrorResponse } from '../../api/common';
import { useCreateReadingPhrase, useOverwriteReadingPhrase } from '../../hooks/readingPhraseHook';
import { ReadingPhraseEditorContext } from '../../ReadingPhraseEditorProvider';
import { ChangePublicConfirmModal } from '../ChangePublicConfirmModal/ChangePublicConfirmModal';
import { Modal, ModalContent, ModalAction } from '../Modal';
import { ReadingPhraseViewer } from './ReadingPhraseViewer';
import './save-reading-phrase-modal.scss';

interface SaveReadingPhraseModalProps {
  phraseId?: string;
  open: boolean;
  onDismiss: () => void;
}
export const SaveReadingPhraseModal = (props: SaveReadingPhraseModalProps) => {
  const { open, onDismiss, phraseId } = props;
  const { draft: phrase } = useContext(ReadingPhraseEditorContext);
  const onSuccess = () => {
    onDismiss();
  };
  const [errorMessage, setErrorMessage] = useState('');
  const onError = (e: AxiosError<ErrorResponse>) => {
    if (e.response?.status === 401) {
      setErrorMessage('Session Expired');
    } else if (e.response?.status === 400) {
      if (e.response.data.code === '4001') {
        setErrorMessage('Cannot save phrase with blank title');
      } else if (e.response.data.code === '4005') {
        setErrorMessage('Cannot save phrase with reserved tags: page, size, ecl, title, type');
      } else {
        setErrorMessage(`Bad Request ${e.response.data.message} ${e.response.data.detail ?? ''}`);
      }
    } else if (e.response?.status === 404) {
      setErrorMessage(`Cannot find value set ${e.response.data.detail ?? ''}`);
    } else {
      setErrorMessage('Failed to save reading phrase');
    }
  };
  useEffect(() => {
    setErrorMessage('');
  }, [open]);

  const save = useCreateReadingPhrase({ onSuccess, onError });
  const overwrite = useOverwriteReadingPhrase({ onSuccess, onError });
  const [privateValueSets, setPrivateValueSets] = useState<ValueSet[]>([]);
  const onClickSave = () => {
    const privateValuesetsInPhrase = Object.entries(phrase?.readingPhraseTree.valueSets ?? {})
      .filter(([id, map]) => map.valueSet.isPublic === false)
      .map(([id, map]) => map.valueSet);
    if (privateValuesetsInPhrase.length !== 0 && phrase?.public !== false) {
      setPrivateValueSets(privateValuesetsInPhrase);
    } else if (phrase) {
      const title = phrase.title.trim();
      const phraseWithTrimmedTitle = { ...phrase, title };
      if (phraseId?.length) {
        overwrite(phraseId, phraseWithTrimmedTitle);
      } else {
        save(phraseWithTrimmedTitle);
      }
    }
  };

  if (!phrase) return null;

  return (
    <>
      <Modal title='Save Reading Phrase' open={open} onDismiss={onDismiss}>
        <ModalContent>
          <ReadingPhraseViewer phrase={phrase} />
        </ModalContent>
        <ModalAction>
          {!!errorMessage.length && (
            <div className='save-reading-phrase-modal__error-message'>
              {errorMessage}
            </div>
          )}
          <button
            onClick={onClickSave}
            className='save-reading-phrase-modal__save-button'
          >
            Save
          </button>
        </ModalAction>
      </Modal>
      <ChangePublicConfirmModal
        open={!!privateValueSets.length}
        onDismiss={() => setPrivateValueSets([])}
        valuesets={privateValueSets}
        onConfirm={() => save(phrase)}
      />
    </>
  );
};
