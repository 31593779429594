import React, { useContext, useCallback } from 'react';
import { useSignOut } from '../../hooks/authHook';
import { ReadingPhraseEditorContext } from '../../ReadingPhraseEditorProvider';
import { Modal, ModalContent } from '../Modal';
import './logout-modal.scss';
import { initialTree } from 'spinel/dist/utils/reading-phrase';

interface LogoutModalProps {
  open: boolean;
  onDismiss: () => void;
}
export const LogoutModal = (props: LogoutModalProps) => {
  const { open, onDismiss } = props;
  const { setDraft } = useContext(ReadingPhraseEditorContext);
  const signOut = useSignOut();

  const onClick = useCallback(() => {
    signOut();
    setDraft((prev) =>
      prev
      ? {
        ...prev,
        readingPhraseTree: initialTree()
      }
      : undefined
    );
    onDismiss();
  }, [signOut]);

  return (
    <Modal title='Logout from HPACS' open={open} onDismiss={onDismiss} small>
      <ModalContent>
        <div className='logout-modal'>
          <label className='logout-modal__label'>
            정말 로그아웃하시겠습니까?
          </label>
          <button className='logout-modal__button' onClick={onClick}>
            LOGOUT
          </button>
        </div>
      </ModalContent>
    </Modal>
  );
};
