import { AxiosResponse, AxiosError } from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { UseMutationOptions } from 'react-query';
import { SavedReadingPhrase } from 'snowphrase-api';
import { ReadingPhrase } from 'spinel/dist/utils/reading-phrase';
import { makeInputPhraseToState, makeStatePhraseToInput } from 'spinel/dist/utils/reading-phrase/utils';
import { ErrorResponse } from '../api/common';
import { ReadingPhraseEditorContext } from '../ReadingPhraseEditorProvider';
import {
  useReadingPhrase,
  useSaveReadingPhrase,
  useEditReadingPhrase,
} from './readingPhrase';

export const useLoadReadingPhrase = (): (s: string) => void => {
  const [phraseId, setPhraseId] = useState('');
  const { data } = useReadingPhrase(phraseId);
  const { setDraft, reset } = useContext(ReadingPhraseEditorContext);
  useEffect(() => {
    if (data?.data) {
      const input = data.data;
      const loadedPhrase = makeInputPhraseToState({
        ...input,
        readingPhraseTree: { ...input.readingPhraseTree, groupIdMap: {} },
      });
      setDraft(loadedPhrase);
      reset(loadedPhrase);
      setPhraseId('');
    }
  }, [data?.data.id, setDraft]);
  return setPhraseId;
};

export const useCreateReadingPhrase = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<any, any>,
      AxiosError<ErrorResponse>,
      ReadingPhrase,
      unknown
    >,
    'mutationFn'
  >
) => {
  const { mutate: save } = useSaveReadingPhrase(options);
  return useCallback(
    (phrase: ReadingPhrase) => {
      save(makeStatePhraseToInput(phrase));
    },
    [save]
  );
};

export const useOverwriteReadingPhrase = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<SavedReadingPhrase, any>,
      AxiosError<ErrorResponse>,
      { phrase: ReadingPhrase; id: string },
      unknown
    >,
    'mutationFn'
  >
) => {
  const { mutate: update } = useEditReadingPhrase(options);
  return useCallback(
    (id: string, phrase: ReadingPhrase) => {
      update({
        phrase: makeStatePhraseToInput(phrase),
        id,
      });
    },
    [update]
  );
};
