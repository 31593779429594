import { AxiosError, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query';
import { GetReadingPhraseBySearchQueryParams, SavedReadingPhrase, SnowphraseClient } from 'snowphrase-api';
import { ReadingPhrase } from 'spinel/dist/utils/reading-phrase';
import { ErrorResponse } from '../api/common';
import { useSpinellaTokenWithExpirationHandler } from './authHook';

export const snowphraseClient = new SnowphraseClient(
  process.env.REACT_APP_BACKEND_URL ?? ''
);

export const READING_PHRASE = 'reading phrase';
export const useReadingPhraseList = (
  params: GetReadingPhraseBySearchQueryParams
) => {
  const getToken = useSpinellaTokenWithExpirationHandler();
  const fetch = useCallback(() => {
    const token = getToken();
    return snowphraseClient.call['getReadingPhraseBySearch'](params, token);
  }, [getToken, params]);
  return useQuery([READING_PHRASE, params], fetch);
};

export const useReadingPhrase = (phraseId: string) => {
  const getToken = useSpinellaTokenWithExpirationHandler();
  const fetch = useCallback(() => {
    const token = getToken();
    return snowphraseClient.call['getReadingPhraseById']({ id: phraseId, token });
  }, [getToken, phraseId]);
  return useQuery([READING_PHRASE, phraseId], fetch, { enabled: !!phraseId.length });
};

export const useSaveReadingPhrase = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<any, any>,
      AxiosError<ErrorResponse>,
      ReadingPhrase,
      unknown
    >,
    'mutationFn'
  >
) => {
  const getSpinellaToken = useSpinellaTokenWithExpirationHandler();
  const client = useQueryClient();
  const mutate = useCallback((phrase: ReadingPhrase) => {
    const token = getSpinellaToken();
    if (!token) {
      throw Error();
    }
    return snowphraseClient.call['postReadingPhrase']({ phrase, token });
  }, [getSpinellaToken]);
  return useMutation(mutate, {
    ...options,
    onSuccess: (...params) => {
      options?.onSuccess?.(...params);
      client.invalidateQueries(READING_PHRASE);
    },
  });
};

export const useEditReadingPhrase = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<SavedReadingPhrase, any>,
      AxiosError<ErrorResponse>,
      { phrase: ReadingPhrase; id: string },
      unknown
    >,
    'mutationFn'
  >
) => {
  const getSpinellaToken = useSpinellaTokenWithExpirationHandler();
  const client = useQueryClient();
  const mutate = useCallback(
    (params: { phrase: ReadingPhrase; id: string }) => {
      const { phrase, id } = params;
      const token = getSpinellaToken() ?? '';
      return snowphraseClient.call['putReadingPhrase']({ id, phrase, token });
    },
    [getSpinellaToken]
  );
  return useMutation(mutate, {
    ...options,
    onSuccess: (...params) => {
      options?.onSuccess?.(...params);
      client.invalidateQueries(READING_PHRASE);
    },
  });
};

export const useDeleteReadingPhrase = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<void, any>,
      AxiosError<ErrorResponse>,
      string,
      unknown
    >,
    'mutationFn'
  >
) => {
  const getToken = useSpinellaTokenWithExpirationHandler();
  const client = useQueryClient();
  const mutate = useCallback(
    (phraseId: string) => {
      const token = getToken() ?? '';
      return snowphraseClient.call['deleteReadingPhrase']({ id: phraseId, token });
    },
    [getToken]
  );
  return useMutation(mutate, {
    ...options,
    onSuccess: (...params) => {
      options?.onSuccess?.(...params);
      client.invalidateQueries(READING_PHRASE);
    },
  });
};
